<template>
    <div class="" v-if="formData.id">
      <ContactForm :form-data="formData" :title="$t('Manage contact')"/>
    </div>
  </template>
  
  <script>
  import ContactForm from "@/views/pages/contacts/Contact-Form"
  import ContactService from "@/services/ContactService"
  
  export default {
    name: "Edit",
    components: {
      ContactForm
    },
    data() {
      return {
        itemId: this.$route.params.itemId,
        ContactService: new ContactService(),
        formData: {
          id: '',
          details: '',
          name: '',
          longitude: '',
          latitude: '',
        },
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        this.ContactService.show(this.itemId).then(res => {
          this.formData = {
            id: res.id,
            full_name: res.full_name,
            zip_code: res.zip_code,
            phone: res.phone,
            email: res.email,
            kids: res.kids,
            kid_name: res.kid_name,
            kid_dob: res.kid_dob,
            model: res.model,
            bookings: res.bookings
          };

          if (res.kid_name && res.kid_dob) {
            this.formData.kids.push({
              name: res.kid_name,
              birthday: res.kid_dob
            });
          }
        })
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  
<template>
  <div class="card">
    <div class="card-body">
      <b-row>
        <b-col md="12" sm="12">
          <b-link to="/contacts" class="back">
            {{ $t('< Back to contact list') }}
          </b-link>
          <h3>{{ $t(title) }}</h3>
        </b-col>
      </b-row>

      <b-form>
        <b-row>
          <b-col md="6" sm="12">
            <b-row>
              <b-col cols="12">
                <b-form-group :label="$t('Parent name')" label-for="full_name">
                  <b-form-input
                    id="full_name"
                    v-model="formData.full_name"
                    :placeholder="$t('Parent name')"
                  />
                </b-form-group>

                <b-form-group :label="$t('Email')" label-for="email">
                  <b-form-input
                    id="email"
                    disabled
                    :value="formData.email"
                    :placeholder="$t('Email')"
                  />
                </b-form-group>

                <b-form-group :label="$t('Phone')" label-for="phone">
                  <b-form-input id="phone" v-model="formData.phone" :placeholder="$t('Phone')" />
                </b-form-group>

                <b-form-group :label="$t('Zip code')" label-for="zip_code">
                  <b-form-input id="zip_code" v-model="formData.zip_code" placeholder="12323" />
                </b-form-group>

                <b-form-group>
                  <b-button
                    variant="primary"
                    class="d-flex"
                    :disabled="submitLoader"
                    @click="submit"
                  >
                    <b-spinner v-if="submitLoader" small class="mr-05" />
                    <span>
                      {{ submitLoader ? $t('Loading...') : $t('Update contact') }}
                    </span>
                  </b-button>
                </b-form-group>
              </b-col>

              <!-- Details -->
              <b-col cols="12" />
            </b-row>
          </b-col>

          <b-col md="6" sm="12">
            <h3>{{ $t('Kids') }}</h3>
            <div
              v-for="kid in formData.kids"
              v-if="formData.model === 'App\\Models\\Contact'"
              class="contact-kid"
            >
              <b-form-group :label="$t('Kid name')" label-for="name">
                <b-form-input
                  id="name"
                  v-model="formData.kid_name"
                  disabled
                  :placeholder="$t('Kid name')"
                />
              </b-form-group>
              <b-form-group :label="$t('Birthday')" label-for="birthday">
                <b-form-input
                  id="birthday"
                  v-model="formData.kid_dob"
                  disabled
                  :placeholder="$t('Birthday')"
                />
              </b-form-group>
              <b-form-group>
                <b-button
                  variant="primary"
                  class="d-flex btn-sm"
                  :disabled="true"
                  @click="updateKid(null)"
                >
                  <span>
                    {{ $t('Update kid') }}
                  </span>
                </b-button>
              </b-form-group>
            </div>
            <div
              v-for="kid in formData.kids"
              v-if="formData.model === 'App\\Models\\Client'"
              class="client-kid"
            >
              <b-form-group :label="$t('Kid name')" label-for="name">
                <b-form-input id="name" v-model="formData.kid_name" :placeholder="$t('Kid name')" />
              </b-form-group>
              <b-form-group :label="$t('Birthday')" label-for="birthday">
                <b-form-input
                  id="birthday"
                  v-model="formData.kid_dob"
                  :placeholder="$t('Birthday')"
                />
              </b-form-group>
              <b-form-group />
            </div>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import { BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BSpinner, BLink } from 'bootstrap-vue'
import { required } from '@/libs/validations'
import ContactService from '@/services/ContactService'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BLink,
  },
  props: {
    title: {
      type: String,
      default: 'Edit contact',
    },
    formData: {
      type: Object,
      default: () => {
        return {
          full_name: null,
          zip_code: null,
          email: null,
          phone: null,
          kid_name: null,
          kid_dob: null,
          kids: [],
        }
      },
    },
  },
  data() {
    return {
      ContactService: new ContactService(),
      submitLoader: false,
      typeId: null,
      required,
    }
  },

  methods: {
    submit() {
      this.submitLoader = true
      this.update()
    },
    update() {
      this.ContactService.update(this.formData.id, this.formData)
        .then(success => {
          this.submitLoader = false
          this.$router.push({ name: 'contacts' })
        })
        .catch(err => {
          this.submitLoader = false
          console.warn(err)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.mr-05 {
  margin-right: 0.5rem;
}

.pac-target-input {
  width: 100%;
  margin-bottom: 15px;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.booking-detail {
  border: 1px solid #dedede;
  padding: 1em;
  border-radius: 8px;
  margin-bottom: 1em;
}

.back {
  display: block;
  margin-bottom: 1em;
}
</style>
